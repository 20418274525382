<template>
	<div class="TextField LongTextField" :class="{ error }">
		<textarea type="text" class="input" v-model="model" @focus="onFocus" @blur="onBlur" @input="updateModelValue" ref="input" :disabled="disabled" :data-cy="dataCy" />
    <FieldTranslationMenu v-if="translateFromLocales.length" :translateFromLocales="translateFromLocales" :locale="locale" v-on="subHandlers" />
		<FieldConstraintsInfo :value="model" :error="error" :min="minSize" :max="maxSize" :disabled="disabled" />
	</div>
</template>

<script>
import { field } from './FieldMixin.js'
import FieldConstraintsInfo from './FieldConstraintsInfo.vue'
import FieldTranslationMenu from "@/components/common/FieldTranslationMenu.vue";
import { translationActions } from '@/constants';

export default {
	components: {FieldTranslationMenu, FieldConstraintsInfo },
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		dataCy: String,
    translateFromLocales: Array
	},
	data: () => ({
		model: null,
	}),
	computed: {
		minSize() {
			return this.validations.size?.min
		},
		maxSize() {
			return this.validations.size?.max ?? 50000
		},
    subHandlers() {
      return translationActions.reduce((handlers, value) => {
        handlers[value.id] = value.id === 'translate'
            ? (args) => this.$emit(value.id, {...args, type: 'text'})
            : () => this.$emit(value.id)
        return handlers
      }, {});
    }
	},
	watch: {
		modelValue(value) {
			this.model = value
			this.validate()
		},
	},
	methods: {
		updateModelValue({ target: { value } }) {
			this.$emit('update:modelValue', value)
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.input { min-height: 80px; max-height: 1000px; width: 100%; resize: vertical; }
.info { display: flex; -webkit-box-pack: justify; justify-content: space-between; font-size: 0.875rem; margin-top: 0.5rem; color: rgb(65, 77, 99); }
.info .status.error { color: rgb(189, 0, 42); }
</style>
