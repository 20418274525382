<template>
	<div class="TiptapField" :class="{ error }">
		<!-- TODO: support @focus + @blur + focus -->
		<TiptapVuetify hide-details required v-model="model" @focus="onFocus" @blur="onBlur" @update:modelValue="$emit('update:modelValue', model)" ref="input"
			class="enabled-text-style"
			:extensions="extensions"
			:disabled="disabled"
			:data-cy="dataCy"
		/>
    <FieldTranslationMenu v-if="translateFromLocales.length" :translateFromLocales="translateFromLocales" :locale="locale" v-on="subHandlers" />
		<FieldConstraintsInfo :value="model" :error="error" :min="validationMin" :max="validationMax" />
	</div>
</template>

<script>
import { field } from './FieldMixin.js'
import { VuetifyTiptap as TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, OrderedList, History, Link } from 'vuetify-pro-tiptap'
import FieldConstraintsInfo from './FieldConstraintsInfo.vue'
import FieldTranslationMenu from "@/components/common/FieldTranslationMenu.vue";
import { translationActions } from '@/constants';

// TODO: change events

export default {
	mixins: [ field ],
	components: {FieldTranslationMenu, TiptapVuetify, FieldConstraintsInfo },
	props: {
		modelValue: [ Number, String ],
		dataCy: String,
    translateFromLocales: Array
	},
	data: () => ({
		model: null,
	}),
	computed: {
		validationMin() {
			return this.validations.size?.min
		},
		validationMax() {
			return this.validations.size?.max ?? 50000
		},
		markMap() {
			return {
				history: History,
				bold: Bold,
				italic: Italic,
				underline: Underline,
				ol: OrderedList,
				ul: BulletList,
				link: Link,
				heading: Heading,
			}
		},
		extensions() {
			const extensions = []
			const enabledMarks = this.validations.enabledMarks

			enabledMarks.forEach(mark => {
				if (mark.toLowerCase().indexOf('heading') > -1) return this.mapHeading(mark, extensions)
				const markExtension = this.markMap[mark.toLowerCase()]
				extensions.push(markExtension)
			})

			return extensions
		},
    subHandlers() {
      return translationActions.reduce((handlers, value) => {
        handlers[value.id] = value.id === 'translate'
            ? (args) => this.$emit(value.id, {...args, type: 'html'})
            : () => this.$emit(value.id)
        return handlers
      }, {});
    }
	},
	watch: {
		modelValue(value) {
			this.model = value
			this.validate()
		},
	},
	methods: {
		mapHeading(mark, extensions) {
			const [_, level] = mark.split('.')
			
			if (!level) return extensions.push(Heading)

			let headingIndex = extensions.findIndex(({ name }) => name === 'heading')

			if (headingIndex === -1) {
				return extensions.push(Heading.configure({ levels: [level] }))
			}

			extensions[headingIndex].configure({ levels: extensions[headingIndex].options.levels.push(level) })
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.input { min-height: 80px; max-height: 1000px; width: 100%; resize: vertical; }
</style>

<style>
.TiptapField .vuetify-pro-tiptap .v-toolbar:last-of-type { display: none; border-top: 1px solid #ddd; }
</style>