<template>
  <div :id="'translationActivator-' + uuid" class="translate-wrapper">
    <v-icon class="translate-icon">$iconAi</v-icon>
  </div>
  <v-menu :activator="'#translationActivator-' + uuid">
      <v-list>
        <v-list-item>
          <v-select
              v-model="selectedFromLocale"
              :items="translateFromLocales"
              variant="outlined"
              density="compact"
              @click.stop
          >
            <template #selection="{ item }">
              <LanguageFlag v-model="item.title" class="mr-3" />
              {{ $t('text.' + getLocaleName(item.title)) }}
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" v-if="item.title !== locale" >
                <template #title></template>
                <LanguageFlag v-model="item.title" class="mr-3" />
                {{ $t('text.' + getLocaleName(item.title)) }}
              </v-list-item>
            </template>
          </v-select>
        </v-list-item>
        <v-list-item v-for="(action, index) in actions" :key="index" @click="onActionClick(action)">
          {{ $t(`text.${action.text}`) }}
        </v-list-item>
      </v-list>
  </v-menu>
</template>

<script lang="ts">
import uniqueId from 'lodash/uniqueId'
import { translationActions } from '@/constants';
import LanguageFlag from "@/components/common/LanguageFlag.vue";

export default {
  components: { LanguageFlag },
  emits: ['settings', 'translate'],
  props: {
    translateFromLocales: Array<string>,
    locale: String
  },
  data() {
    return {
      actions: translationActions,
      selectedFromLocale: null,
    }
  },
  mounted() {
    // set locale to translate from to 'de' as default and the next available if the field itself has locale 'de'
    if (this.locale !== 'de' && this.translateFromLocales.includes('de')) this.selectedFromLocale = 'de'
    else this.selectedFromLocale = this.translateFromLocales.find(l => l !== this.locale)
  },
  methods: {
    getLocaleName(lang: string): string {
      const mappedStoreLocaleName = this.$store.state.locales.find(l => l.code === lang)?.name
      return mappedStoreLocaleName ? this.$t(mappedStoreLocaleName) : lang
    },
    onActionClick(action): void {
      const args = {}
      if (action.id === 'translate') {
        args['fromLocale'] = this.selectedFromLocale
      }
      this.$emit(action.id, args)
    }
  },
  beforeCreate() {
    this.uuid = uniqueId()
  }
}
</script>

<style lang="scss">
.translate-wrapper {
  .translate-icon path {
    fill: var(--color-blue);
  }
}
.v-input__details {
  display: none;
}
</style>/
